import React from "react";
import styled from "styled-components";
import { Grid, Divider } from "@material-ui/core";
import RaisedContent from "./RaisedContent";

const ItemsListingGrid = ({ items, itemsPerRow, component: Component }) => {
  const getGridSize = (itemsPerRow) =>
    ({
      2: { xs: 12, sm: 6, md: 6, lg: 4, xl: 6 },
      3: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 },
    }[itemsPerRow] || { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 });

  const sizes = getGridSize(itemsPerRow);

  return (
    <RaisedContent>
      <Grid container spacing={3}>
        {items.map(({ node }) => {
          return (
            <Grid
              item
              xs={sizes.xs}
              sm={sizes.sm}
              md={sizes.md}
              lg={sizes.lg}
              xl={sizes.xl}
            >
              <Component key={node.slug} {...node} />
            </Grid>
          );
        })}
      </Grid>
    </RaisedContent>
  );
};

export default ItemsListingGrid;
