import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import BlogPostCard from "../components/BlogPostCard";

import ListingPage from "../layouts/ListingPage";

const Blog = (props) => {
  const siteTitle = get(props, "propsdata.site.siteMetadata.title");
  const posts = get(props, "data.allContentfulBlogPost.edges");
  return (
    <ListingPage
      title={"Our latest news, offers and services"}
      siteTitle={siteTitle}
      backgroundImageUrl={"/banner.jpg"}
      location={props.location}
      items={posts}
      component={BlogPostCard}
    />
  );
};

export default Blog;

export const pageQuery = graphql`
  query BlogQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            file {
              url
              contentType
            }
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
