import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import ItemsListingGrid from "../components/ItemsListingGrid";
import Banner from "../components/Banner";

const ListingPage = ({ title, items, component, location, itemsPerRow }) => {
  return (
    <Layout location={location}>
      <div>
        <Helmet>
          <title>{`${title} | Diamantidou School of Music, Dance & Drama`}</title>
        </Helmet>
        <Banner title={title} imageUrl={`/banner.jpg`} />
        <ItemsListingGrid
          items={items}
          itemsPerRow={itemsPerRow}
          component={component}
        />
      </div>
    </Layout>
  );
};

export default ListingPage;
